import './App.css';
import React, { useEffect, useState } from "react";
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

function VideoInput(props) {
  // This component represents a single video element.
  // It includes controls to set the video source.

  const [videoSource, setVideoSource] = useState("");
  const [fileOrUrl, setFileOrUrl] = useState("file-input");

  const onChangeHandler = e => {
    e.preventDefault();
    setVideoSource(e.target.value);
  };

  const onFileInputHandler = e => {
    e.preventDefault();
    setVideoSource(e.target.files?.item(0));

    //*******
    console.log("getting file");
    var file = e.target.files[0];
    if (!file) return;
    console.log("Got file");
    var reader = new FileReader();
    reader.onload = function(e) {
      //document.getElementById('contents').innerHTML = e.target.result;
      console.log(e.target.result);
    }
    reader.readAsText(file)
    //*******


  };

  const onFormSubmitHandler = e => {
    e.preventDefault();
    props.callBack(videoSource)
  }

  return(
    <div >
      <form onSubmit={onFormSubmitHandler} className="video-src-form" >
        <RadioInput setInputType={setFileOrUrl}/>
        <div hidden={fileOrUrl=="file-input"}>
          <label className="form-element">URL </label>
          <input className="form-element" type="text" value={videoSource}  onChange={onChangeHandler}/>
        </div>
        <div hidden={fileOrUrl=="url-input"}>
          <label className="form-element">File</label>
          <input className="form-element" type="file"  onChange={onFileInputHandler}/>
        </div>
        <input className="form-element" type="submit" value="Get" className="default-button src-submit"/>
      </form>
    </div>
  )

}

function RadioInput(props) {
  //This compoent represents a radio toggel input to choose between file or URL

  const [radioSetting, setradioSetting] = useState("file-input");

  const onChangeHandle = e => {
    setradioSetting(e.target.value);
    props.setInputType(e.target.value);
  };

  return (
    <>
      <div className="form-element radio-element">
        <input className="form-line-element" type="radio" name="file-button" value="file-input"
          onChange={onChangeHandle}
          checked={radioSetting === "file-input"}
        />
        <label className="form-line-element" htmlFor="file-button">file</label>
        <input className="form-line-element" type="radio" name="url-button" value="url-input"
          onChange={onChangeHandle}
          checked={radioSetting === "url-input"}
        />
        <label className="form-line-element" htmlFor="url-button">url</label>
      </div>
    </>
  )
}

function VideoInfoOutput(props) {
  //This compoent represents the output area to display the video info

  var infoAreaHideShow = props.displayFlag? "overlay-area show-div" : "overlay-area hide-div";

  return (
    <div className={infoAreaHideShow}>
      <div className="overlay-inset-area">
        <h1>
          Video Info
        </h1>
        <h2>
          {props.displayMessage}
        </h2>
        <p>
          {props.fileInfo.map(entry =>
            <p>{entry}</p>)}
        </p>
      </div>
    </div>
  )
}

function App() {

  const [statusMessage, setStatusMessage] = useState('');

  const [fileInfoArray, setFileInfoArray] = useState([]);

  const ffmpeg = createFFmpeg({ log: true });

  ffmpeg.setLogger(({ type, message }) => {
    //Crude text filtering but will do for now as the info we want is
    //a selection of ffinfo and fferr logs
    if (type == "fferr") {
      if ( !( (message.startsWith("ffmpeg version") ||
               message.includes("built with") ||
               message.includes("configuration:") ||
               message.includes("libavutil") ||
               message.includes("libavcodec") ||
               message.includes("libavformat") ||
               message.includes("libavdevice") ||
               message.includes("libavfilter") ||
               message.includes("libswscale") ||
               message.includes("libswresample") ||
               message.includes("libpostproc") ||
               message.includes("At least one output file must be specified") ) ) ) {
               setFileInfoArray(oldFileInfoArray => [...oldFileInfoArray,  message]);
      }
    }
  });

  const doGetInfo = async (videoSrc) => {
    setStatusMessage('Loading ffmpeg-core.js');  // <-------- This will only have effect after next render as it is a constant for this render
    console.log(statusMessage);
    setFileInfoArray([]);
    await ffmpeg.load();
    setStatusMessage('Start info getting'); // <-------- This will only have effect after next render as it is a constant for this render
    //******const videofile = await fetchFile(process.env.PUBLIC_URL + '/videos/bbb_PCM_48_16.mp4');
    const videofile = await fetchFile(videoSrc);
    ffmpeg.FS('writeFile', 'test.mp4', videofile);
    await ffmpeg.run('-i', 'test.mp4');
    //const videoInfoOutput = ffmpeg.FS('readFile', 'output.txt');
    //console.log(videoInfoOutput);
    setStatusMessage('Video Info for ' + videoSrc); // <-------- This will only have effect after next render as it is a constant for this render
    console.log(statusMessage);
  };

  return (
    <div className="App">
      <div className="app-main">
        <p className="para">
          Get Video Info
        </p>
        {<VideoInput callBack={doGetInfo} />}
        {<VideoInfoOutput displayFlag="true" displayMessage={statusMessage} fileInfo={fileInfoArray}/>}
        <p className="footer">
          Built with <a href="https://www.ffmpeg.org">FFmpeg</a> and <a href="https://github.com/ffmpegwasm/ffmpeg.wasm">FFMPEG.WASM</a>
        </p>
      </div>
    </div>
  );
}

export default App;
